<template>
  <footer>
    <div
      :class="`s-bg-primary s-text-center s-ptb-5 ${
        isMobile || !collapse ? '' : 's-ml-320'
      }`"
    >
      <p class="s-text-white">
        Borobudur DRM Communication Platform © 2021, Departemen Arsitektur
        Universitas Atma Jaya Yogyakarta
      </p>
    </div>
  </footer>
</template>


<script>
export default {
  computed: {
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    collapse() {
      return this.$store.getters["getCollapse"];
    },
  },
};
</script>

